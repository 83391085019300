import { JSO, Popup } from 'jso';
/* import { SalteAuth } from '@salte-auth/salte-auth';
import { Auth0 } from '@salte-auth/auth0';
import { Popup } from '@salte-auth/popup'; */
import config from '../../../data/config/config';
import fetchHelper from 'src/core/util/FetchHelper';
import { wipeToken, setUserData, wipeUserData, getToken, setToken } from './OAuth2Persistence';

const LOG_PREF = '[OAuth2] ';
let client;

const {
  authorization_url,
  client_id,
  redirect_uri,
  client_secret,
  response_type,
  eventcode,
  community_code,
  providerID,
} = config.OAuth2;

export function loginOAuth2() {
  console.log(`${LOG_PREF} loginOAuth2`);

  /* let inAppBrowserRef;
  const authenteUrl = `${authorization_url}/StartOAuthLogin.aspx?client_id=${client_id}&redirect_uri=${redirect_uri}&eventcode=${eventcode}`;
  if (global.isCordovaContext) inAppBrowserRef = cordova.InAppBrowser.open(authenteUrl, '_blank');
  else
    inAppBrowserRef = window.open(
      authenteUrl,
      'EANITHING',
      'toolbar=no,location=no, directories=no, status=yes, menubar=no, resizable=yes, copyhistory=no, scrollbars=no, width=600, height=600'
    );

  function executeScriptCallBack(params) {
    if (params[0]) {
      inAppBrowserRef.close();
      console.log('medbk params', params);
    }
  }

  inAppBrowserRef.addEventListener('loadstart', function() {
    setTimeout(function() {
      inAppBrowserRef.executeScript(
        {
          code:
            '(function() { if(window.response){return window.response;} else{return null;} })()',
        },
        executeScriptCallBack
      );
    }, 500);
  });
 */


    let client = new JSO({
    providerID: providerID,
    client_id: client_id,
    redirect_uri: redirect_uri,
    eventcode: eventcode,
    authorization: `${authorization_url}/StartOAuthLogin.aspx`, 
    response_type: response_type,
    token: `${authorization_url}/API/GetAccessToken`,
    client_secret: client_secret,
   // scopes: { request: [`${authorization_url}/API/GetUserInfoRequest`] }, 
    debug: true,
  });
  client.callback()
  console.log('medbk client', client)
  const loaderOptions = {
    width: "400px",
    height: "640px",
    title: "super auth popup"
  };
  let opts = {
    redirect_uri: redirect_uri,
    client_secret: client_secret,
    client_id: client_id,
    response_type: response_type,
    token: `${authorization_url}/API/GetAccessToken`,
  }
  
  client.setLoader(Popup);
  client.getToken(opts)
	.then((token) => {
		console.log("medbk I got the token: ", token)
	})
	.catch((err) => {
		console.error("medbk Error from passive loader", err)
	}) 
}

const onAuth = (error, { provider }) => {
  if (error) console.error(error);
  else {
    const { idToken, accessToken, code } = auth.provider(provider);
    console.log('medbk auth', auth.provider(provider));
  }
};

export const onUserLogInOAuth2 = (data) => {
  if (data) {
    let userData = config.OAuth2.userData(JSON.parse(data));
    getBindedActions().setLoginStatus(true, userData, null);
    getBindedActions().userDataUpdated(userData);
    setUserData(userData);
  }
};

export const logOutOAuth2 = () => {
  console.log(`${LOG_PREF}logOutOAuth2`);
  wipeToken();
  wipeUserData();
  client.wipeTokens();
};

export const getParticipantInfo = () => {
  console.log(`${LOG_PREF}getParticipantInfo`);

  const url = `${authorization_url}/API/GetParticipantInfo?event_code=${event_code}`;
  fetchHelper(
    url,
    { Authorization: getToken().access_token }, // options
    true, // is json
    // on success:
    (json) => {
      const new_datas = json;
    },
    global.noop, // on failure
    false // no error message
  );
};

export const getCommunityMemberInfo = () => {
  console.log(`${LOG_PREF}getCommunityMemberInfo`);

  const url = `${authorization_url}/API/GetCommunityMemberInfo?community_code=${community_code}`;
  fetchHelper(
    url,
    { Authorization: getToken().access_token }, // options
    true, // is json
    // on success:
    (json) => {
      const new_datas = json;
    },
    global.noop, // on failure
    false // no error message
  );
};

export const refreshAccessToken = () => {
  console.log(`${LOG_PREF}refreshAccessToken`);

  const url = `${authorization_url}/API/RefreshAccessToken?client_id=${client_id}&client_secret=${client_secret}&refresh_token=${
    getToken().refresh_token
  }`;
  fetchHelper(
    url,
    null, // options
    true, // is json
    // on success:
    (json) => {
      const new_datas = json;
    },
    global.noop, // on failure
    false // no error message
  );
};
