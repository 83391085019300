// Codifications that can be retrieved using web service /getCodifs.htm
// See ticket SAN-15
// See https://mobile-spot.atlassian.net/wiki/spaces/FR/pages/18677787/UserData+Networking+WebServices+request
var CODIFICATIONS_SOURCES = {
  EXHIBIS: 'EXHIBIS'
};
var CODIFICATIONS_ENTITIES = {
  INDIVIDUEVT: 'IndividuEvt'
};
var CODIFICATIONS_NAMES = {
  FONCTION: 'ZL_FONCTION',
  PROFIL: 'ZL_PROFIL',
  SERVICE: 'ZL_SERVICE',
  CENTREINTERET: 'ZL_CENTREINTERET'
};
var CODIFICATION_FONCTION = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.FONCTION
};
var CODIFICATION_PROFIL = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.PROFIL
};
var CODIFICATION_SERVICE = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.SERVICE
};
var CODIFICATION_CENTREINTERET = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.CENTREINTERET
};
var CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE = [CODIFICATION_FONCTION, CODIFICATION_PROFIL, CODIFICATION_SERVICE, CODIFICATION_CENTREINTERET];
var FETCH_ON_LOGIN_SUCCESS = false;
module.exports = {
  CODIFICATION_FONCTION: CODIFICATION_FONCTION,
  CODIFICATION_PROFIL: CODIFICATION_PROFIL,
  CODIFICATION_SERVICE: CODIFICATION_SERVICE,
  CODIFICATION_CENTREINTERET: CODIFICATION_CENTREINTERET,
  CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE: CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE,
  FETCH_ON_LOGIN_SUCCESS: FETCH_ON_LOGIN_SUCCESS
};