import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';
import { isSessionValid } from 'src/core/login/LoginService';
import './VimeoPlayer.scss';

const LOG_PREF = '[VimeoPlayer] ';

class VimeoPlayer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.horaireAutorized = this.horaireAutorized.bind(this);
  }

  formateDate = (timestamp, time) => {
    var formatedDate;
    if (timestamp && time) {
      var tmpTime = time.split('h');
      var tmpDate = moment.utc(timestamp).toDate();
      formatedDate = new Date(
        tmpDate.getFullYear(),
        tmpDate.getMonth(),
        tmpDate.getDate(),
        parseInt(tmpTime[0], 10),
        parseInt(tmpTime[1], 10),
        0
      );
      formatedDate = new Date(formatedDate.toDateString()).getTime();
      return formatedDate;
    }
    return null;
  };

  horaireAutorized() {
    const { start_date, start_time, end_time, end_date } = this.props;
    if (start_date && start_time && end_time) {
      const currentDate = new Date(new Date().toDateString()).getTime();
      const dateBeginTime = this.formateDate(start_date, start_time);
      const dateEndTime = this.formateDate(end_date || start_date, end_time);
      return !(dateBeginTime > currentDate || dateEndTime < currentDate);
    }
    return false;
  }

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  render() {
    const { id } = this.props;
    const { loading } = this.state;

    if (
      config.PLAYER_EMBED_VIMEOLIVE.FEATURE_ENABLED !== true ||
      !isSessionValid() ||
      this.horaireAutorized() !== true
    ) {
      return null;
    }

    const url = `https://player.vimeo.com/video/${id}&title=0&byline=0&portrait=0`;
    return (
      <div className="embed-container">
        {loading ? <div class="spinner"></div> : null}
        <iframe
          src={url}
          onLoad={this.hideSpinner}
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}

VimeoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  start_date: PropTypes.number.isRequired,
  end_time: PropTypes.string,
  end_date: PropTypes.string,
  // common props
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default VimeoPlayer;
