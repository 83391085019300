import config from 'data/config/config';

const LOCALSTORAGE_LOGIN_KEY = 'loginTokenOAuth2';
const LOCALSTORAGE_USERDATA_KEY = 'userDataOAuth2';
const LOCALSTORAGE_CODE_KEY = 'codeOAuth2';

/**
 * TOKEN getter
 * @return {string}
 */
export function getToken() {
  return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOGIN_KEY));
}

/**
 * TOKEN setter
 * @param {string} value
 */
export function setToken(value) {
  window.localStorage.setItem(LOCALSTORAGE_LOGIN_KEY, JSON.stringify(value));
}

/**
 * TOKEN wipe
 */
 export function wipeToken() {
  window.localStorage.removeItem(LOCALSTORAGE_LOGIN_KEY);
}

/**
 * TOKEN EXPIRATION getter
 * @return {number}
 */
 export function getTokenExpiry() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_LOGIN_KEY));

  return data  && data.expires_in ? data.expires_in : null;
}

/**
 * USER DATA getter
 * @return {object}
 */
export function getUserData() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USERDATA_KEY));

  return data || null;
}

/**
 * USER DATA setter
 * @param {object}
 */
export function setUserData(value) {
  window.localStorage.setItem(LOCALSTORAGE_USERDATA_KEY, JSON.stringify(value));
}

/**
 * USER DATA wipe
 * @param {object}
 */
 export function wipeUserData() {
  window.localStorage.removeItem(LOCALSTORAGE_USERDATA_KEY);
}

/**
 * CODE getter
 * @return {object}
 */
 export function getCode() {
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_CODE_KEY));

  return data || null;
}

/**
 * CODE setter
 * @param {object}
 */
export function setCode(value) {
  window.localStorage.setItem(LOCALSTORAGE_CODE_KEY, JSON.stringify(value));
}

/**
 * CODE wipe
 * @param {object}
 */
 export function wipeCode() {
  window.localStorage.removeItem(LOCALSTORAGE_CODE_KEY);
}