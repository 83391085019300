import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_COUNTRIES, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_NEWPRODUCT_CATEGORIES } from 'data/config/dataConfig';
import config from 'data/config/config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY // LOGIN_PAGE_KEY,
, MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import AdBanner from 'src/components/ad-banner/AdBanner';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';

var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE; // NB: used for ChooseProfile page too !


export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/home_background.jpg'), ") no-repeat"),
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 2
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 0.65,
  // rowspan
  width: 1 // colspan

};
var tileBgGradient = 'linear-gradient(to bottom,rgba(252, 252, 252, 0.1), rgba(245, 245, 245, 0.9))';

var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem( // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  homebutton["label_".concat(lang)], // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)],
        target = homebutton["target"];

    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }

  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.miseEnRelation', // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  }, // Tile options
  GENERAL_TILE_OPTIONS);
}

var getEventsTile = function getEventsTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Programme.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.programme', // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_PAGE_KEY, {
    inputs: [{
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }],
    hasGoToSynopticButton: true
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getServicesTile = function getServicesTile() {
  return (//createItemRoute(
    createItem( // Style
    {
      container: {
        /* style: {
          backgroundImage: tileBgGradient,
          boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
          margin: '0.5rem',
        }, */
        className: 'home-btn-text'
      },
      icon: {
        style: {
          backgroundImage: "url(".concat(getUrl('files/project/home/Info.svg'), ")")
        } // className: 'home-icon-programme',

      }
    }, // Label (i18n)
    'home.practicalInfo', function (actions) {
      var url = 'https://sofcot.regtime.fr/mobile/infos-pratiques.htm';

      if (openUrl(url, null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
        actions.linkClicked(url);
      }
    }, // Page & props

    /* LIST_PAGE_KEY,
       { inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }], hasGoToSynopticButton: false }, */
    // { inputs: [{ dataType: DATA_TYPE_SERVICES }] },
    // Tile options
    GENERAL_TILE_OPTIONS)
  );
};

var getSanitaryProtocolTile = function getSanitaryProtocolTile() {
  return (//createItemRoute(
    createItem( // Style
    {
      container: {
        /* style: {
          backgroundImage: tileBgGradient,
          boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
          margin: '0.5rem',
        }, */
        className: 'home-btn-text'
      },
      icon: {
        style: {
          backgroundImage: "url(".concat(getUrl('files/project/home/protocole_sanitaire.svg'), ")")
        } // className: 'home-icon-programme',

      }
    }, // Label (i18n)
    'home.protocole', function (actions) {
      var url = 'https://sofcot.regtime.fr/mobile/mesures-sanitaires-participants.htm';

      if (openUrl(url, null, config.TOU_LINK_TARGET, config.TOU_LINK_OPENININAPPBROWSER)) {
        actions.linkClicked(url);
      }
    }, // Page & props

    /* LIST_PAGE_KEY,
       { inputs: [{ dataType: DATA_TYPE_SERVICE_CATEGORIES }], hasGoToSynopticButton: false }, */
    // { inputs: [{ dataType: DATA_TYPE_SERVICES }] },
    // Tile options
    GENERAL_TILE_OPTIONS)
  );
};

var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exhibitors.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.exhibitors', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_EXHIBITORS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNewPapersTile = function getNewPapersTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Abstracts.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.papers', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_PAPER_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNewProductTile = function getNewProductTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Exhibitors.svg'), ")")
      } // className: 'home-icon-programme',

    }
  }, // Label (i18n)
  'home.newproducts', // Page & props
  LIST_PAGE_KEY, // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      dataType: DATA_TYPE_NEWPRODUCT_CATEGORIES
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getSpeakersTile = function getSpeakersTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Speakers.svg'), ")")
      } // className: 'home-icon-intervenants',

    }
  }, // Label (i18n)
  'home.speakers', // Page & props
  LIST_PAGE_KEY, {
    contextualTitle: '',
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getSocialMediaTile = function getSocialMediaTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/News.svg'), ")")
      }
    }
  }, // Label (i18n)
  'home.medias', // Page & props
  MEDIAS_PAGE_KEY, null, // {
  //   controlMediaPageContent: {
  //     // hideTabMediaSocial: true,
  //     mediaTabKey: 'twitter',
  //   },
  // },
  // Tile options
  GENERAL_TILE_OPTIONS);
};

var getMapTile = function getMapTile() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Plan.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.map', // Page & props
  MAP_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getNotifications = function getNotifications() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.notifications', // Page & props
  INBOX_PAGE_KEY, null, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getVideos = function getVideos() {
  return createItemRoute( // Style
  {
    container: {
      /* style: {
        backgroundImage: tileBgGradient,
        boxShadow: '1px 2px 1px rgba(0,0,0,.1)',
        margin: '0.5rem',
      }, */
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      } // className: 'home-icon-plan',

    }
  }, // Label (i18n)
  'home.videos', // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube' // to match index of social Media

    }
  }, // Tile options
  GENERAL_TILE_OPTIONS);
};

var getDefault = function getDefault(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    } // component: AdBanner,
    // props: {
    //   ad: true,
    //   adFiles: adConfig.adsFiles,
    // },

  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['klipsoleads', 'contactScan']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      tiles: [// Programme
      getEventsTile(), // Speakers
      getSpeakersTile(), // Abstracts
      getNewPapersTile(), // Exposants
      getExhibitorsTile(), // PLAN
      getMapTile(), // INFOS PRATIQUES
      getServicesTile(), // Sanitary Protocol
      getSanitaryProtocolTile(), // Social Medias
      getSocialMediaTile() // Innovations
      // getNewProductTile(),
      // Mise en relation
      // getParticipantsTile(),
      // Notifications
      // getNotifications(),
      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, // {
  //   component: AdSwap,
  //   props: {
  //     adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
  //   },
  // },
  {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
}; // const getExhibitor = (lang, adConfig, orientation, isLoggedIn) => {
//   const hasAdButtons =
//     adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
//   return [
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER,
//       },
//     },
//     {
//       component: HomeToolbar,
//       props: {
//         // Black list of buttons just to avoid side effect on older projects
//         disabledButtons: ['inbox', 'contactScan'],
//       },
//     },
//     {
//       component: HomeTiles,
//       props: {
//         baseColumnCount: BASE_COLUMN_COUNT[orientation],
//         maxColumnSize: MAX_COLUMN_SIZE[orientation],
//         tiles: [
//           // Exposants
//           getExhibitorsTile(),
//           // Programme
//           getEventsTile(),
//           // Speakers
//           getSpeakersTile(),
//           // PLAN
//           getMapTile(),
//           // Mise en relation
//           getParticipantsTile(),
//           // INFOS PRATIQUES
//           getServicesTile(),
//           // Notifications
//           getNotifications(),
//           // Social Medias
//           getSocialMediaTile(),
//           // Videos
//           getVideos(),
//           // INTERACTIVITE ATELIERS
//           // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
//         ],
//       },
//     },
//     {
//       component: AdSwap,
//       props: {
//         adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME,
//       },
//     },
//     {
//       component: MobilespotBar,
//     },
//     {
//       component: IphonexFlexMargin,
//     },
//   ];
// };

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */


export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  return getDefault(lang, adConfig, orientation, isLoggedIn); // switch (profile) {
  //   // PRO
  //   case 'visitor':
  //     return getDefault(lang, adConfig, orientation, isLoggedIn);
  //   // GRAND PUBLIC
  //   case 'exhibitor':
  //     return getExhibitor(lang, adConfig, orientation, isLoggedIn);
  //   default:
  //     if (!profile) {
  //       console.warn(LOG_PREF + 'No profile set yet');
  //     } else {
  //       console.error(LOG_PREF + 'Unsupported profile: ' + profile);
  //     }
  // }
}