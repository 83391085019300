var defaultConf = require('./config-default');

var BO_URL = 'sofcot2020-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://sofcot2020mobilspot.site.calypso-event.net/';
var NODE_BACKEND_DOMAIN = 'sofcot2020-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'sofcot2020-node-backend-ws-dev.mobile-spot.com';

var _require = require('./dataConfig'),
    DATA_TYPE_EVENT_CATEGORIES = _require.DATA_TYPE_EVENT_CATEGORIES,
    DATA_TYPE_EVENTS = _require.DATA_TYPE_EVENTS;

module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/eventime/sofcot/sofcot2019/sofcot-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/eventime/sofcot/sofcot2019/sofcot-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-133313314-2',
  LOGIN: {// forgottenPasswordURL: `${BO_TAIGA_URL}/mobile/authentification/mot-de-passe-oublie.htm`,
  },
  projectId: '314283355843',
  // FCM sender id
  appId: 'E0CC6-543F0',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  // add per environnement
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  SOCIAL: {
    FEATURE_ENABLED: true
    /* TWITTER: {
        POST_PER_PAGE: 10,
      }, */
    // YOUTUBE: {
    //   POST_PER_PAGE: 5,
    // },
    // URL: `https://${NODE_BACKEND_DOMAIN}/social`,

  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  // parent_Id is unique for every project
  SYNOPTIC_AGENDA: {
    AUTOFOCUS_TAB: true,
    SYNOPTIC_DEFAULT_EVENT_BGCOLOR: '#b4b5b4',
    DEFAULT_INPUT: {
      agendaProps: {
        input: {
          parentId: 12,
          parentType: DATA_TYPE_EVENT_CATEGORIES,
          dataType: DATA_TYPE_EVENTS
        }
      }
    }
  }
});