import React from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_KLIPSO_LEADS_CONTACTS } from 'data/config/dataConfig';
import { KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';
import STATUS from 'src/store/fetchStatuses';

import Header from '../header/Header';
import Content from '../content/Content';
import StatusBlock from '../status-block/StatusBlock';
import SortSearchBar from './sort-search-bar/SortSearchBar';
import SearchArea from './search-area/SearchArea';
import ScanResult from './scan-result/ScanResult';
import Footer from '../footer/Footer';
import FooterSyncButton from '../footer/FooterSyncButton';
import FooterScanButton from '../footer/FooterScanButton';
import FooterGenericButton from '../footer/FooterGenericButton';
import OverlayLoader from '../overlay-loader/OverlayLoader';
import DummyKeyboardPlaceholder from 'src/components/dummy-keyboard-placeholder/DummyKeyboardPlaceholder';

import { renderList } from 'src/components/list/listUtil';
import { parseListOfGroups } from 'src/components/list/listOfGroupsUtil';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { parseStringifiedDateFromKLeadsBackend } from 'src/core/webservices/klipso-leads/KlipsoLeadsWS';

import './MainScreen.scss';

global.klDebugScanResult = false;

class MainScreen extends React.Component {
  state = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.onScan = this.onScan.bind(this);
  }

  navigateToHome = () => {
    this.props.actions.navigateToHome();
  };

  onScan({ result, resultFormat }) {
    if (!result) {
      this.props.actions.badgeScanWithoutResult();
    } else if (global.klDebugScanResult) {
      this.setState({
        scanResult: result,
        scanResultFormat: resultFormat,
      });
    } else if (typeof result !== 'object' || !result.code) {
      this.props.actions.showNotification({
        message: this.props.labels.klipsoLeads.invalidVisitorBadge,
        level: NotificationLevels.WARNING,
      });
    } else {
      this.props.actions.klipsoLeadsEnableFormScreen(result);
    }
  }

  isFooterVisible = () => !this.props.keyboardHeight;

  render() {
    let { actions, labels, context } = this.props;

    let content, sideIndex;

    // Search enabled
    if (this.props.searchEnabled) {
      content = (
        <SearchArea
          results={this.props.searchResults}
          searchedValue={this.props.searchedValue}
          labels={labels}
          actions={actions}
        />
      );
    }
    // Debug: show scanned badge content
    else if (this.state.scanResult) {
      content = (
        <ScanResult result={this.state.scanResult} resultFormat={this.state.scanResultFormat} />
      );
    }
    // No result
    else if (this.props.totalContactsCount < 1) {
      content = <div className="kl-list-empty">{labels.klipsoLeads.listEmpty}</div>;
    }
    // Items sorted by company
    else if (this.props.sortedByCompany) {
      content = renderList({
        items: this.props.items,
        status: STATUS.FETCHED,
        favIconDisabled: true,
        actions: actions,
        labels: labels,
        pageKey: KLIPSO_LEADS_PAGE_KEY,
      });
    }
    // Items sorted by last modification date
    else {
      let parseResult = parseListOfGroups({
        groupedItems: this.props.items,
        dataType: DATA_TYPE_KLIPSO_LEADS_CONTACTS,
        favIconDisabled: true,
        isPending: false,
        pageKey: KLIPSO_LEADS_PAGE_KEY,
        actions: actions,
        labels: labels,
      });
      content = parseResult.content;
      sideIndex = parseResult.sideIndex;
    }
    // let dateBeginTime = parseStringifiedDateFromKLeadsBackend(context.event.dateBegin) // https://mobile-spot.atlassian.net/browse/KL-9
    let dateEndTime = parseStringifiedDateFromKLeadsBackend(context.event.dateEnd)
    let currentDate = new Date(new Date().toDateString()).getTime()

    return (
      <div className="kl-main">
        <Header
          labels={labels}
          actions={actions}
          onCloseClick={this.navigateToHome}
          subtitle={context.exhibitor.name}
        />

        <Content>
          {this.props.syncOngoing && <OverlayLoader label={labels.klipsoLeads.ongoingSync} />}

          <div className="kl-main-not-scrollable">
            <StatusBlock
              eventName={context.event.name}
              contactsCount={this.props.totalContactsCount}
              nonSyncContactsCount={this.props.nonSyncContactsCount}
              labels={labels}
            />

            {this.props.totalContactsCount > 0 && !this.props.searchEnabled && (
              <SortSearchBar
                sortedByCompany={this.props.sortedByCompany}
                setSortedByCompany={actions.klipsoLeadsSetSortedByCompany}
                enableSearchArea={actions.klipsoLeadsEnableSearch}
                labels={labels}
              />
            )}
            <div className="kl-main-list-container">
              {content}
              {sideIndex}
            </div>

            <DummyKeyboardPlaceholder keyboardHeight={this.props.keyboardHeight} />
          </div>
        </Content>

        {this.isFooterVisible() && (
          <Footer>
            <FooterSyncButton
              totalContactsCount={this.props.totalContactsCount}
              nonSyncContactsCount={this.props.nonSyncContactsCount}
              actions={actions}
            />
            <FooterScanButton
              disabled={
                // dateBeginTime > currentDate ||  // https://mobile-spot.atlassian.net/browse/KL-9
                dateEndTime < currentDate
              }
              disabledMessage={labels.klipsoLeads.disabledScanButtonMessage}
              actions={actions}
              labels={labels}
              callback={this.onScan}
            />
            <FooterGenericButton
              className="far fa-ellipsis-h"
              callback={() => {
                actions.klipsoLeadsEnableExtendedMenuScreen();
              }}
            />
          </Footer>
        )}
      </div>
    );
  }
}

MainScreen.propTypes = {
  context: PropTypes.object, // licence, userName, exhibitor, event, checkpointId...
  items: PropTypes.object.isRequired,
  nonSyncContactsCount: PropTypes.number.isRequired,
  totalContactsCount: PropTypes.number.isRequired,
  syncOngoing: PropTypes.bool.isRequired,
  sortedByCompany: PropTypes.bool.isRequired,
  searchEnabled: PropTypes.bool.isRequired,
  searchResults: PropTypes.object,
  searchedValue: PropTypes.string,
  keyboardHeight: PropTypes.number,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default MainScreen;
